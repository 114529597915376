<template>
    <div class="quill_box">
        <quill-editor style="height:400px"
            ref="myQuillEditor"
            v-model="html"
            :options="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @change="onEditorChange"
            @ready="onEditorReady($event)">
        </quill-editor>
    </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor' // 调用编辑器
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
  
  export default {
     props: {
      value: {
        type: [Number, Object, Array, String],
        default: "",
      },
    },
    data () {
      return {
        html:this.value,
        editorOption: {
        },
      }
    },
    watch: {
      html: {
        handler(newValue) {
          this.$emit("input", newValue);
        },
        deep: true,
      },
      value: {
        handler(newValue, oldValue) {
          if (newValue !== oldValue) this.html = newValue;
        },
        deep: true,
      },
    },
    methods: {
      onEditorBlur(quill) {
        // console.log('editor blur!', quill)
        // console.log(this.html)
        this.$emit("input", this.html);
      },
      onEditorFocus(quill) {
        // console.log('editor focus!', quill)
      },
      onEditorReady(quill) {
        // console.log('editor ready!', quill)
      },
      onEditorChange({ quill, html, text }) {
        // console.log('editor change!', quill, html, text)
        this.html = html
        this.$emit("input", this.html);
      }
    },
    computed: {
      editor() {
        return this.$refs.myQuillEditor.quill
      }
    },
    mounted() {
      
    },
    components:{
      quillEditor
    }
  }
</script>