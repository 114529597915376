import request from './request.js'

export function uploadLogo(params) {
    return request({
        url: 'agentImage/uploadImage.do?',
        method: 'post',
        data: params,
		timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}
export function downLoadLogo(params) {
    return request({
        url: 'agentImage/loadAgentImage.do?',
        method: 'post',
        data: params,
		
    })
}

//QA列表
export function getQAPageList(params) {
    return request({
        url: 'QA/getPageList.do?',
        method: 'post',
		data: params,
    })
}

export function addQA(params) {
    return request({
        url: 'QA/add.do?',
        method: 'post',
		data: params,
    })
}
export function updateQA(params) {
    return request({
        url: 'QA/update.do?',
        method: 'post',
		data: params,
    })
}
export function deleteQA(params) {
    return request({
        url: 'QA/delete.do?',
        method: 'post',
		data: params,
    })
}