<template>
    <div class="editor_actical fadeInRight">
        <div class="title-box">
            <span class="m-title">文本编辑</span>
        </div>

        <el-form :model="form" ref="form" label-width="120px" :rules="rules" style="margin-top:10px;">   
            <el-form-item label="文章标题:" prop="title" >
                <el-input v-model="form.title" placeholder="请输入文章标题" style="width:80%"></el-input>
            </el-form-item>

            <!-- <el-form-item label="所属类别:" prop="mark">
                <el-select v-model="form.mark" filterable placeholder="请选择类别" style="width:80%">
                    <el-option v-for="(item,index) in articleTypeList" :label="item.accountName" :value="item.id" :key="index"></el-option>
                </el-select>
            </el-form-item>   -->
            <el-form-item label="内容编辑:"> </el-form-item> 
        </el-form>
        
        <div class="editor_box" style="margin-top:20px;">
            <editor :value="content" @input="getArticalContent"></editor>  
        </div>
        <div class="right_align" style="margin-top:50px;">
            <el-button type="primary" @click="onSure('form')">确定</el-button>
        </div>
        

    </div>
</template>

<script>


import {updateQA,addQA} from "@/api/setting.js"

import Editor from "@/components/editor.vue"
export default {
    name:'editorArtical',
    data(){
        return {
            type:"",
            info:this.$route.query.info,
            form:{
                title:""
            },
            content:"",
            articleTypeList:[],
            rules:{
                title:{ required: true, message: '请填写文章标题', trigger: 'blur' }
            }
        }
    },
    created(){
        this.info = JSON.parse(this.info)
        this.type = this.info.type
        if(this.type == "edit"){
            this.form.title = this.info.title 
            this.content = this.info.html 
        }
        
    },

    methods: {
      onSure(formName){
          this.$refs[formName].validate((valid) => {
            if (valid) {
                if(this.content == ""){
                    this.$modal.alertWarning("请输入文本内容")
                    return
                }
                let parama = {
                    question:this.form.title,
                    answer:this.content
                }
                if(this.type == "add"){  
                    addQA(parama).then(res => {
                        this.$modal.alertSuccess("添加成功")
                        this.$router.push({
                            path:'/systemManagement/QA',
                        })
                    })
                }else{
                    parama.id = this.info.id
                    updateQA(parama).then(res => {
                        this.$modal.alertSuccess("编辑成功")
                        this.$router.push({
                            path:'/systemManagement/QA',
                        })
                    })
                }
                
            } else {
                return false;
            }
        });

      },
      getArticalContent(html){
        //   console.log(html)
        this.content = html
      }

    },
    components:{
        Editor
    }
}
</script>
<style>
.editor_actical .el-form-item__label{font-weight:600}
</style>